.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover{
    color:gray;
}
.nav-link.active {
    color: white !important;
    background-color: #1e81b0 !important;
}
.nav-link {
    color: black !important;
    background-color: rgb(238, 236, 236) !important;
    height: 100%;
}

.container-fluid {
    position: relative;
}

@media screen and (max-width: 565px)  {
    .nav-tabs {
        display: flex !important;
        flex-direction: column;
    }
  }