.text-row-late td{
    color: #dc3545 !important;
}

.text-row-discount td {
    color: rgba(var(--bs-primary-rgb))!important;
}

.selected td{
    background-color: rgba(0, 0, 0, 0.2) !important;
}

@media screen and (max-width: 991.98px) {
    .write-all-debt-btn-bottom{
        margin-bottom: 3%;
    }
    .error-page-height{
        min-height: 200px !important;
    }
}

@media screen and (min-width: 992px) {
    .error-page-height{
        min-height: 200px !important;
    }
}

.spinner{
    width: 3rem !important; 
    height: 3rem !important;
}

tr {
    cursor: pointer;
}

.vh-120 {
    width: 120px!important;
}

.vh-140 {
    width: 140px!important;
}
.vh-250 {
    width: 250px!important;
}

.vh-80 {
    width: 80px!important;
}

.vh-155 {
    width: 155px!important;
}